.selector-panel {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button{
    margin: 7px;
    max-width: calc(100% - 14px);
  }
}