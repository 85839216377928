.beach-visualizer {
  background-color:  #fbeeb5;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-attachment: local;
  background-size:100%;
  height:100%;
  width:100%;
  position:relative;
}

.beach-organizer {
  position:relative;
  height:100%;
  overflow:hidden;
  display:flex;
}

.beach-editor {
  flex:1;
  position:relative;
  overflow:hidden;
  background-color:  #fbeeb5;
}

.beach-header {
    position: absolute;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    background: rgba(0,0,0,0.4);
    height: 50px;
    padding: 0 16px;

    h1 {
      flex-grow:1;
      font-weight: 600;
      line-height: 1.125;
    }
}

.beach-mode.buttons {
  flex-grow:1;
  margin-bottom:0;
}

.beach-sidebar {
  width:250px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  & > *{
    width: 100%;
  }
}

.board {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.grid {
  margin: auto;
  width: 80%;
  padding: $pm-size;
  position:relative;
}

.grid:after {
  content:"";
  display:block;
  padding-bottom:100%;
}
.grid .content {
  width:100%;
  height:100%;
  position:absolute;
}

.BoardSquare {
  position: relative;
  width: 100%;
  height: 100%;
}

.BoardItem {
  position:relative;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);


  .seatName {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width:90%;
  }
}

.seatPrice {
  background: transparent linear-gradient(270deg, #FF5353 0%, #FFA788 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: 5px;
  left: 0;
  font-size: 13px;
  width: 35px;
  height: 28px;
  font-weight: 600;
  letter-spacing: -1px;
  color: #FFFFFF;
  display: block;
  padding: 0 5px;
  border-radius: 8px;
  line-height: 28px;
  text-align: center;
}

.mouse-select{
  cursor: pointer;
} 


.mouse-move {
  cursor: move;
}


.Square {
  color: $danger;
  width: 100%;
  height: 100%;
  border:1px dashed #E5D695;
}

//-----------------------------------


.selectors {
  display:flex;
  flex-direction: column;
  height: calc(100% - 100px);
  overflow-x: hidden;
  overflow-y: auto;
  grid-gap: 0;
  width:100%;
}

.selector {
  min-height: 70px;
  width: 100%;
  cursor: pointer;
  transition: all .3s ease;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $grey-lighter;
  font-size: .8rem;
  padding: 0 $pm-size-small;
  position: relative;

  &:first-child {
    border-top: 1px solid $grey-lighter;
  }

  &button{
    border: none;
    
    &:hover {
      border: none;
    }
  }

  img {
    width:60px;
    margin-right: $pm-size;
  }

  .selector-icon {
    color: $danger;
    width: 60px;
    text-align: center;
    font-size: 24px;
  }

  &:hover {
    border-color: $grey-darker;
  }

  .circleSelector {
    box-shadow: 0 1px 2px rgba(0,0,0,0.4);
    border: 2px solid white;

    &Wrapper {
      width: 60px;
      height: 60px;
      margin-right: $pm-size;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    width: 40px;
    height: 40px;
    border-radius: $radius-round;
  }

  .selectorName {
    flex:1;
    font-size: 15px;
    line-height: 17px;
    padding: 8px 0;
    font-weight: 700;

    p{
      font-style: italic;
      opacity: 0.8;
      font-size: 12px;
      line-height: 13px;
      font-weight: normal;
      margin-top: 4px;
    }
  }

  .actionsButton {
    width: 50px;
  }

  &.selected {
    background: $gradient-horizontal;
    border-color: transparent;
    // transform: scale(1.2); ca ca fait planter la modal 
  }
}

button.minusColumnButton {
  padding: 0;
  font-size: 0.7rem;
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: $radius-round;
  cursor:pointer;
}

button.minusRowButton {
  position: absolute;
  left: -30px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: $radius-round;
  padding: 0;
  font-size: 0.7rem;
  width: 1.2rem;
  height: 1.2rem;
  cursor:pointer;
}

.buttons.has-addons {
  button.button.beach-editor-menu--button {
    background-color: transparent;
    border: none;
    color: $light;
    margin: 0;

  
    &:hover {
      color: $grey-darker
    }
  
    &.is-active {
      background: $gradient-horizontal;
      color: $light;
      border-radius: $radius-round;
    }
  }
}

button.itemElement.beach-editor-menu--button {
  background-color: $dark;
  color: white;
  border-radius: 100%;
  height: 25px;
  width: 24px;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
}

.BoardItem:hover button.itemElement.beach-editor-menu--button{
  opacity: 1;
  visibility: visible;
}


.gridTop {
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  margin: auto;
  width: 260px;
  height: 50px;
  background: none;
    border: 1px dashed #333;
    border-radius: 10px;
    cursor:pointer;
}

.gridBottom {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 260px;
  height: 50px;
  z-index:4;
  background: none;
    border: 1px dashed #333;
    border-radius: 10px;
    cursor:pointer;
}

.gridLeft {
  position: absolute;
  bottom: 0px;
  left: -90px;
  top:0;
  margin: auto;
  width: 50px;
  height: 260px;
  background: none;
    border: 1px dashed #333;
    border-radius: 10px;
    cursor:pointer;
}

.gridRight {
  position: absolute;
  bottom: 0;
  top:0;
  right: -70px;
  margin: auto;
  width: 50px;
  height: 260px;
  margin: auto;
  background: none;
    border: 1px dashed #333;
    border-radius: 10px;
    cursor:pointer;
}


.BoardItem-dragging {
  opacity: 0.5;
}

.zoom{
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: revert;
  border-radius: 40px;
  overflow: hidden;

  button {
    background: rgba(0,0,0,0.8);
    color: white;
    border: 0;
    font-size: 24px;
    cursor: pointer;
    font-weight: bold;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    & + button{
      border-left: 1px solid rgba(255,255,255,0.1);
    }
  }
}

.map-scale{
  position: absolute;
  bottom: 3rem;
  left: 1.5rem;
  display: flex;
  flex-direction: revert;
  border-radius: 40px;
  overflow: hidden;

  span {
    background: rgba(0, 0, 0, 0.8);
    color: white;
  }

  button {
    background: rgba(0,0,0,0.8);
    color: white;
    border: 0;
    font-size: 24px;
    cursor: pointer;
    font-weight: bold;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    & + button{
      border-left: 1px solid rgba(255,255,255,0.1);
    }
  }
}


.mapInfo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #dedede;
  padding: 5px;
  font-size: 12px;
  border-radius: 10px;
}

.seatNumber{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: #11111190;
  color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
  font-weight: bold;
}