.menu {
  background-color: $menu-background-color;
  color: $light;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  @include transition(0.3s);
  padding-bottom: 1rem;

  &::-webkit-scrollbar-track {
    background-color:rgba(255,255,255,0.1);
  }

  &::-webkit-scrollbar {
    z-index: 10;
    width: 5px;
    height: 5px;
    background-color:rgba(255,255,255,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.3);
    width: 3px;
    height: 5px;
  }


 

  &.is-open {
    width: $menu-size;
  }

  &-header {
    position: relative;
    background: transparent;
    padding-bottom: 0;
    padding-top: 1rem;

    position: sticky;
    top: 0;
    background: $dark;
    z-index: 10;
    
    &--mask {
      height: 55px;
      margin-top: -35px;
      background: $dark-light;
    }
  }

  &-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    // background-color:white;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 20px auto 0;
    border-bottom: 1px solid #282735;
    width: 150px;
    height: 150px;
    border-radius: 100%;
  }

  &-beach-select {
    padding: $pm-size;
    position: fixed;
    background-color: $menu-background-color;
    width: $menu-size - 5px;
  }

  &-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 $pm-size;
    
    &--logout.button {
      padding: 0;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color:#282735;
      &:hover {
        animation: pulse-red 2s infinite;
      }
    }

    &--name {
      font-size: 0.8rem;
      color: #747e8a;
    }
  }

  &-container {
    padding: 1rem;
    padding-top: 5rem;
  }

  &-label,
  &-list{
    .icon{
      width: 30px;
      justify-content: flex-start;
    }
  }
  
  &-label {
    opacity: 1;
    font-weight: normal;
    text-transform: none;
    font-size: 0.9rem;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.8);
    //font-family: $family-title;


    &:hover{
      background-color: transparent;
      color: rgba(255, 255, 255, 0.8);
    }

    i {
      color: $primary;
      margin-right: 0.5rem;
    }
  }

  &-list > li > a,
  &-list > li > span.no-link {
    @include transition(0.3s);
    font-size: 1rem;
    opacity: 1;
    border-radius: 4px;
    color: #f4f4f4;
    display: block;
    padding: 0.5em 0.75em;

    &:hover{
      background-color: rgba(0,0,0,0.2);
      color: $light;
    }

    i {
      color: rgba(255,255,255,0.2);
      &.icon-emphasis{
        color: $success;
      }
    }
  }
}

@media screen and (max-height: 450px) {
  .menu {
    padding-top: 15px;
  }
  .menu a {
    font-size: 18px;
  }
}