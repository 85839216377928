// Custom fonts

@font-face {
    font-family: Lato;
    src: url('./fonts/Lato/Lato-Regular.ttf') format("truetype");
  }
  
  @font-face {
    font-family: LatoBold;
    src: url('./fonts/Lato/Lato-Bold.ttf') format("truetype");
  }
  
  @font-face {
    font-family: Avenir;
    src: url('./fonts/Avenir/Avenir-Medium.ttf') format("truetype");
  }
  
  @font-face {
    font-family: AvenirBold;
    src: url('./fonts/Avenir/Avenir-Black.ttf') format("truetype");
  }
  
  @font-face {
    font-family: Roboto;
    src: url('./fonts/Roboto/Roboto-Regular.ttf') format("truetype");
  }
  
  @font-face {
    font-family: RobotoBold;
    src: url('./fonts/Roboto/Roboto-Bold.ttf') format("truetype");
  }
  
  @font-face {
    font-family: RobotoCondensed;
    src: url('./fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf') format("truetype");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format("truetype");
  }
  
  @font-face {
    font-family: MontserratBold;
    src: url('./fonts/Montserrat/Montserrat-Bold.ttf') format("truetype");
  }
  
  @font-face {
    font-family: Bebas;
    src: url('./fonts/Bebas/BebasNeue.otf') format("opentype");
  }
  
  @font-face {
    font-family: Rubik;
    src: url('./fonts/Rubik/Rubik-Bold.ttf') format("truetype");
  }
  
  @font-face {
    font-family: PalanquinDark;
    src: url('./fonts/Palanquin_Dark/PalanquinDark-Bold.ttf') format("truetype");
  }
  
  @font-face {
    font-family: Sniglet;
    src: url('./fonts/Sniglet/Sniglet-Regular.ttf') format("truetype");
  }
  @font-face {
    font-family: AbrilFatface;
    src: url('./fonts/Abril_Fatface/AbrilFatface-Regular.ttf') format("truetype");
  }
  
  @font-face {
    font-family: ZillaSlab;
    src: url('./fonts/Zilla_Slab/ZillaSlab-Regular.ttf') format("truetype");
  }
  
  @font-face {
    font-family: ZillaSlabBold;
    src: url('./fonts/Zilla_Slab/ZillaSlab-Bold.ttf') format("truetype");
  }
  