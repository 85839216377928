.order-details {
  &--item {
    background-color: $white;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 8fr;
    height: 4rem;

    &:not(:last-child) {
      margin-bottom: $pm-size-small;
    }

    &-img {
      background-size: cover;
      background-position: center center;
    }

    &-content {
      padding: 0 0 0 $pm-size;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-name {
      font-weight: bold;
      padding-right: $pm-size-small;
    }

    &-total {
      font-size: 1.6rem;
      font-weight: bold;
      color: $grey-darker;
    }
  }
}