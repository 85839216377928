
.SquareOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.overlay-red {
  background-color: $danger;
}

.overlay-yellow {
  background-color: rgb(255, 153, 0);
}

.overlay-green {
  background-color: $success;
  opacity: 0.8;
}

.overlay-blue {
  background-color: blue;
}

.overlay-orange {
  background-color: rgb(255, 30, 0);
}


.selectedSeats {
  flex: 1;
}

.selectedSeat {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;

  .seatName {
    flex: 1;
  }

  img {
    width: 75px;
  }
}

.callInfo {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    text-align: center;

    img {
      width: 94%;
    }
}

.callInfo.late {
  background-color: rgba(255,0,0,0.5);
}

.itemName {
  position: absolute;
  top: 5px;
  margin: auto;
  left: 5px;
  right: 5px;
  text-align: left;
  background-color: rgba(0,0,255,0.5);
  padding: 2px;
}

.orderInfo {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  text-align: center;

  img {
    width: 94%;
  }
}

.orderInfo.late {
background-color: rgba(255,0,0,0.5);
}

.modal-card {
  width: 60%;
}

.zoom{
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: revert;
  border-radius: 40px;
  overflow: hidden;

  button {
    background: rgba(0,0,0,0.8);
    color: white;
    border: 0;
    font-size: 24px;
    cursor: pointer;
    font-weight: bold;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    & + button{
      border-left: 1px solid rgba(255,255,255,0.1);
    }
  }
}

.mapInfo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #dedede;
  padding: 5px;
  font-size: 12px;
  border-radius: 10px;
}

.search-seat {
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;

  input{
    border: none;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 15px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    &:focus{
      outline: none;
    }
  }


}

.seatNumber{
  position: absolute;
  background: #11111190;
  color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
  font-weight: bold;
}