.order-state-tag {
  background: $gradient-red;
  color: $light;
  padding: $pm-size-small;
  border-radius: $radius-round;
  display: flex;
  line-height: 0;
  justify-content: center;
  align-items: center;
  padding: 5px ​10px;

  &.is-done {
    background: $gradient-green;
  }

  .fas {
    margin-right: $pm-size-small;
    margin-bottom: -2px;
  }

  i + span{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 50px;
    display: block;
    line-height: 15px;
  }
}