.area-section-row {
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: flex-end;
}

.font-weight-bold {
    font-weight: bold;
}

.preparator-area-section {
    margin-top: 50px;
}

.assignLink{
    padding: 8px;
    background: #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 8px;

    p{
        font-style: italic;
        font-size: 14px;
        text-align: left;
    }
}