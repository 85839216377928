.login-page {
  width: 40%;
  margin: 0 auto;
}

.logo {
  margin: 0 auto 30px;
  width: 200px;
  height: 200px;
  background-size: cover;
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $pm-size;

  p {
    margin-bottom: .75rem;
  }
}

button.is-success.submit-btn {
  background: $gradient-horizontal;
  border: none;
  transition: all .3s ease;
  
  &:hover {
    background: $gradient-horizontal-dark;
    transition: all .3s ease;
  }
}