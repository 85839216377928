html, body,#root {
  height:100%;
  width:100%;
}

h1.title {
  color: $primary;
}

h2.subtitle {
  color: black;
}

a.button,
button.button,
input.button {
  border-radius: $radius-small;
  font-weight: bold;
  @include transition(all 0.3s ease);

  &.is-outlined {
    box-shadow: none;
  }

  &.is-small {
    border-radius: $radius;
    padding-left: $pm-size;
    padding-right: $pm-size;
  }
}

.input {
  border-radius: $radius-small;
  caret-color: $primary;
  @include transition(all 0.3s ease);
  &::placeholder {
    color: $dark;
  }

  &.has-button {
    height: calc(2.25rem + 4px);
  }
}

.table-link {
  color: $primary;
  &:hover {
    color: darken($primary, 10);
    text-decoration: underline;
    cursor: pointer;
  }
}

.card {
  border-radius: $radius;
}

.lead {
  font-weight: 700;
}

.border-radius {
  border-radius: $radius;
}

.navbar-item {
  @include transition(all 0.3s);
}

section.section {
  & + section{
    margin-top: 16px;
  }

  &.list-page, &.form-page {
    padding-top: 0;
    margin-top: -4rem;
  }

  section.section{
    padding: 0;
  }

  &.form-page {
    background-color: $white;
    border-radius: $radius;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding: 1.5rem;

  }
}

.table {
  td {
    display: flex;
    align-items: center;
    padding: .25rem .75rem !important;
  }
}

.link {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: darken($primary, 10);
    text-decoration: underline;
  }
}


.modal-card,
.form-page,
.beach-sidebar,
thead,
tbody{
  .react-select__control{
    min-height: 36px;
  }

  .react-select__placeholder{
    white-space: nowrap;
    width: 100%;
  }

  .react-select__indicator{
    padding: 4px;
  }
}

.modal-card,
.form-page,
.beach-sidebar,
tbody{

  label.label{
    font-size: 0.9rem;
    font-weight: 600;
  }

  .input, .textarea, .select select, .react-select__control,
  .date-picker .DayPickerInput input{
    border-color: $grey-darker;
    color: $dark;
    &:hover{
      border-color: $grey-darker;
    }

    &--is-focused,
    &:focus,
    &:hover:focus{
      border-color: $primary;
    }
  }


 
}

.row{
  display: flex;
  & > *{
    flex: 1;
    & + *{
      margin-left: 12px;
    }
  }
}


.is-checkradio[type="checkbox"] + label::after, .is-checkradio[type="checkbox"] + label:after {
  top: 0.35rem;
}

.checkbox-input.is-vertical{
  .is-checkradio[type="checkbox"] + label{
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 14px;

    &:before{
      top: 30px;
    }

    &:after{
      top: 35px
    }
  }
}