.title-filter{
  input{
    border-radius: $radius;
  }
}


.date-picker {
label {
  margin-bottom: $pm-size-small;
  display: block;
}

.DayPickerInput {
  width: 100%;
  max-width: 200px;

  input{
    background: transparent;
    color: white;
    border-color: $dark-lighter;
    &:focus{
      border-color: $primary;
    }
  }

  .DayPickerInput-OverlayWrapper{
    z-index: 10;
    .DayPickerInput-Overlay{
      left: 0;
      right: auto;

      .DayPicker-Day{
        line-height: 35px;
        min-width: 35px;
        height: 35px;
        padding: 0;
        &--selected{
          color: white !important;
        }

        &:focus{
          outline: none;
        }
      }
    }
  }

}

.DayPicker {
  color: #1d1c26;
}
}

.rc-time-picker{
  width: 100%;
  &-input{
    width: 100%;
    height: 36px;
    border-color: #bdc3c7;
    &:focus{
      border-color: $primary;
      outline: 0;
    }
  }
}
