.my-collapse {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.card-content {
  width: 70%;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: 20px;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
}

.card-header {
  justify-content: space-between;
}

.card-children {
  margin-bottom: 10px;
}