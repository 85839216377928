// Custom framework
@import 'custom-variables';
@import 'fonts';
@import 'mixins';
@import 'sizings';
@import 'positions';
@import 'colors';
@import 'animations';
@import 'globals';

// Bulma
@import '~bulma/bulma';
@import '~bulma-extensions/bulma-checkradio/dist/css/bulma-checkradio';

@include is-lighter();

.is-divider {
display: block;
    position: relative;
    border-top: .1rem solid #dbdbdb;
    height: .1rem;
    margin: 2rem 0;
    text-align: center;

  &:after {
    background: #fff;
    color: #b5b5b5;
    content: attr(data-content);
    display: inline-block;
    font-size: .75rem;
    padding: .4rem .8rem;
    -webkit-transform: translateY(-1.1rem);
    -ms-transform: translateY(-1.1rem);
    transform: translateY(-1.1rem);
    text-align: center;
  }
}

.control.has-icons-left .icon {
  color: $grey-darker;
}

main {
  position:relative;
}

.helpText {
  font-style: italic;
  font-size: 14px;
  padding-bottom: $pm-size;
}
