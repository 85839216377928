.order-details {
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $pm-size;
  }
  
  &--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $pm-size;
    border-top: 1px solid #ddd;
    padding-top: 25px;
    margin-top: 12px;
  }

  &--meta {
    flex-grow: 1;
  }
  
  &--id {
    color: $dark;
    font-size: 1.2rem;
    font-weight: bold;
  }

  &--state {
    margin-left: $pm-size-small;
    flex-grow: 1.5;
  }

  &--total {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #ddd;
    margin-bottom: 12px;
    padding-bottom: 12px;


    &-price {
      margin-left: $pm-size-small;
      font-size: 1.6rem;
      color: $primary;
    }

  }
  &--code {
    
  }
}