.register-page {
  width: 40%;
  margin: 0 auto;
}

.logo {
  margin: 0 auto 30px;
  width: 200px;
  height: 200px;
  background-size: cover;
}